import Vue from 'vue'
import template from './_help-links.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  methods: {
    goToPrivacyPolicy() {
      this.$emit('privacy-policy')
    },

    openLanguageSelector() {
      this.$emit('change-language')
    },

    goToTransportRequest() {
      this.$emit('transport-request')
    },

    goToClaims() {
      this.$emit('claims')
    },

    showContactModal() {
      this.$emit('show-contact-modal')
    },

    goToAdvertising() {
      this.$emit('advertising')
    },

    goToInstitutionalInformation() {
      this.$emit('institutional-information')
    },

    goToPrivacidadePolicy() {
      this.$emit('privacidade-policy')
    },

    goToCookiesPolicy() {
      this.$emit('cookies-policy')
    },

    goToRenting() {
      this.$emit('renting')
    }
  }
})
