import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'

import CityImage from 'assets/images/cities/icons/city.svg'

import SessionMenu from 'js/components/layout-menu/_session-menu.js'
import AddressInfo from 'js/components/address-info/index.js'
import ComplaintsBook from 'js/components/complaints-book/index.js'
import FollowUs from 'js/components/follow-us/index.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'

import HelpLinks from './_help-links.js'

import template from './index.pug'

// @vue/component
export default Vue.extend({
  name: 'sidebar-custom-rd',
  template: template(),
  
  components: {
    SessionMenu, AddressInfo, ComplaintsBook, FollowUs, HelpLinks
  },

  mixins: [ContactMixin],

  props: {
    controlMenu: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['currentMunicipality', 'canSelectMunicipality']),

    municipalityIcon() {
      if (this.currentMunicipality) {
        return this.currentMunicipality.image_url || CityImage
      }
      return undefined
    }
  },

  methods: {
    ...mapActions(['showAccessibilityModal', 'openLanguageModal']),

    closeMenu() {
      this.$emit('close-menu')
    },

    logout() {
      this.closeMenu()
      this.$emit('logout')
    },

    login() {
      this.closeMenu()
      this.$emit('login')
    },

    register() {
      this.closeMenu()
      this.$emit('register')
    },

    goToHome() {
      this.closeMenu()
      this.$router.push({name: 'home'})
    },

    goToPrivacyPolicy() {
      this.closeMenu()
      this.$router.push({name: 'privacy-policy'})
    },

    goToTransportRequest() {
      this.closeMenu()
      this.$router.push({name: 'transport-request'})
    },

    goToClaims() {
      this.closeMenu()
      this.$router.push({name: 'claims'})
    },

    openLanguageSelector() {
      this.closeMenu()
      this.openLanguageModal()
    },

    goToContactModal() {
      this.closeMenu()
      this.openContactModal()
    },

    goToInstitutionalInformation() {
      this.closeMenu()
      this.$router.push({name: 'institutional-information'})
    },

    goToPrivacidadePolicy() {
      this.closeMenu()
      this.$router.push({name: 'privacidade-policy'})
    },

    goToCookiesPolicy() {
      this.closeMenu()
      this.$router.push({name: 'cookies-policy'})
    },

    goToAdvertising() {
      this.closeMenu()
      this.$router.push({name: 'advertising'})
    },

    goToRenting() {
      this.closeMenu()
      this.$router.push({name: 'renting'})
    }
  }
})
