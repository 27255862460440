import Vue from 'vue'
import ContactsService from 'js/services/api/contacts.js'
import ContactMixin from 'js/mixins/contact-modal-mixin'
import {mapGetters} from 'vuex'
import template from './index.pug'


Vue.component('contact-modal', {
  name: 'contact-modal',
  template: template(),

  mixins: [ContactMixin],

  data() {
    return {
      email: '',
      motive: '',
      observations: '',
      acceptConditions: false,
      formErrors: {},
      acceptConditionsError: '',
      fonts : ["cursive"],
      captchaValue: '',
      inputCaptcha:''
    }
  },

  computed: {
    ...mapGetters([ "contraste"]),
    contactSelectOptions() {
      const options = this.selectOptions

      this.selectOptions.forEach(option => {
        option.label = this.$t(option.label)
      })

      return options
    },
    
  },

  created() {
    if (this.$user) {
      this.email = this.$user.email
    }
  },
  mounted(){
    this.gencaptcha()
    this.setcaptcha()
   
    
  },

  methods: {
    submit() {
      this.resetErrors()
      if(this.inputCaptcha=== this.captchaValue){
          if (this.acceptConditions ) {
            this.sendContact()
          } else {
            this.acceptConditionsError = this.$t('contact.conditionsAcceptError')
          }
        }else{
          this.sendNotificationFormErrors()
          this.formErrors["inputCaptcha"] = this.$t('contact.captcha')
      }
      
    },
    resetErrors() {
      this.formErrors = {}
      this.acceptConditionsError = ''
    },
    sendContact() {
      const data = {
        email: this.email,
        motive: this.motive,
        observations: this.observations
      }

      ContactsService.create(data).then(() => {
        this.sendNotificationSuccess()

        this.close()
      }, response => {
        if (response.status === 422) {
          this.sendNotificationFormErrors()

          this.formErrors = response.data.errors
         
        } else {
          this.sendNotificationFailure()
        }
      })
    },
    close() {
      this.$emit('close')
    },
    showConditions() {
      if (this.$currentAppConfig.code === 'gc') {
        const routeData = 'https://autgc.org/aviso-legal/'
        window.open(routeData)
      } else {
        const routeData = this.$router.resolve({
          name: 'privacy-policy'
        })
        window.open(routeData.href)
      }
    },
    sendNotificationSuccess() {
      this.$notifications.send({
        id: 'contact-success',
        text: this.$t('contact.successMessage'),
        type: 'success',
        close: true
      })
    },
    sendNotificationFormErrors() {
      this.$notifications.send({
        id: 'contact-form-errors',
        text: this.$t('contact.formErrorsMessage'),
        type: 'error',
        close: true
      })
    },
    sendNotificationFailure() {
      this.$notifications.send({
        id: 'contact-failure',
        text: this.$t('contact.failureMessage'),
        type: 'error',
        close: true
      })
    },
    gencaptcha()
    {
      
        let value = btoa(Math.random()*1000000000);
        value = value.substr(0,5 + Math.random()*5);
        this.captchaValue = value;
    },
    setcaptcha()
    {
        let html = this.captchaValue.split("").map((char)=>{
          const rotate = -20 + Math.trunc(Math.random()*30);
          const font = Math.trunc(Math.random()*this.fonts.length);
          return`<span
          style="
          transform:rotate(${rotate}deg);
          font-family:${font[font]};
          "
          >${char} </span>`;
      }).join("");
      document.querySelector(".preview").innerHTML = html;
    },
    applyContrasteCss(){
      // console.log(`applying high contrast for component ${this.$options.name} (${this.contraste})`)
      if(this.contraste){
        $(".preview ").css( "cssText" ,"color: #1e1e1e !important")
      }else{
        $(".preview ").css( "cssText" ,"color:  ")
      }
    }
  }
})
