const Recharge = () => import('js/pages/recharge/index.js')
const News = () => import('js/pages/news')
const DownloadableResources = () => import('js/pages/downloadable-resources')
const PrePurchaseFares = () => import('js/pages/pre-purchase-fares')
const Renting = () => import('js/pages/renting')
const Advertising = () => import('js/pages/advertising')

const meta = {
  layoutInfo: true
}

export default [
  {
    path: 'recharge',
    name: 'municipality-recharge',
    component: Recharge,
    meta
  },
  {
    path: ':media_code/:media_type/recharge',
    name: 'municipality-recharge-data',
    component: Recharge,
    meta
  },
  {
    path: 'news/:slug?',
    name: 'municipality-news',
    component: News,
    meta
  },
  {
    path: 'fares',
    name: 'municipality-fares',
    component: DownloadableResources,
    props: {type: 'fares'},
    meta
  },
  {
    path: 'schedules',
    name: 'municipality-schedules',
    component: DownloadableResources,
    props: {type: 'schedules'},
    meta
  },
  {
    path: 'pre-purchase-fares',
    name: 'municipality-pre-purchase-fares',
    component: PrePurchaseFares,
    meta
  },
  {
    path: 'renting',
    name: 'renting',
    component: Renting,
    meta
  },
  {
    path: 'advertising',
    name: 'advertising',
    component: Advertising,
    meta
  }
]
