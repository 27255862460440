const Home = () => import('js/pages/home/index.js')
const Goodbye = () => import('js/pages/goodbye/index.js')
const Recharge = () => import('js/pages/recharge/index.js')
const News = () => import('js/pages/news')
const Profile = () => import('js/pages/profile/index.js')
const Faqs = () => import('js/pages/faqs/index.js')
const InstitutionalInformation = () => import('js/pages/institutional-information/index.js')
const CookiesPolicy = () => import('js/pages/cookies-policy/index.js')
const PrivacidadePolicy = () => import('js/pages/privacidade-policy/index.js')
const PrivacyPolicy = () => import('js/pages/privacy-policy/index.js')
const Claims = () => import('js/pages/claims/index.js')

// META:
// forbiddenWithOneMunicipality: ruta prohibida si sólo hay 1 municipio

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      mixinLayout: true,
      watchPosition: true,
      forbiddenWithOneMunicipality: true
    }
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: Faqs,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/news/:slug?',
    name: 'news',
    component: News,
    meta: {
      layoutInfo: true,
      layout: 'default',
      forbiddenWithOneMunicipality: true
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: Recharge,
    meta: {
      layoutInfo: true,
      forbiddenWithOneMunicipality: true
    }
  },
  {
    path: '/:municipality_id/:media_code/:media_type/recharge',
    name: 'recharge-data',
    component: Recharge,
    meta: {
      layoutInfo: true,
      forbiddenWithOneMunicipality: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      layoutInfo: true,
      layout: 'default',
      auth: true
    }
  },
  {
    path: '/institutional-information',
    name: 'institutional-information',
    component: InstitutionalInformation,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/cookies-policy',
    name: 'cookies-policy',
    component: CookiesPolicy,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/privacidade-policy',
    name: 'privacidade-policy',
    component: PrivacidadePolicy,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/claims',
    name: 'claims',
    component: Claims,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  },
  {
    path: '/goodbye',
    name: 'goodbye',
    component: Goodbye,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  }
]
