import Vue from 'vue'
import {SOCIAL_MEDIA, DEFAULT, TELEPHONE} from 'js/lib/info.js'

import {mapGetters,mapActions} from 'vuex'

import AppLogo from 'js/components/layout-menu/_app-logo.js'
import PhoneNumber from 'js/components/phone-number/index.js'
import SessionMenu from 'js/components/layout-menu/_session-menu.js'
import MunicipalitySelector from 'js/components/layout-menu/header/_municipality-selector.js'

import ContactMixin from 'js/mixins/contact-modal-mixin'

import LogoOriginal from 'assets/images/logo.svg'
import LogoBlack from 'assets/images/logoBlack.svg'
import LogoBlanco from 'assets/images/logoBlanco.svg'

import IconoAccesibilityBlanco from 'assets/images/iconoaccessibilityblanco.svg'
import IconoAccesibility from 'assets/images/iconoaccesibility.svg'

import libroReclamoBlanco from 'assets/images/libroReclamoBlanco.svg'
import IconoContraste from 'assets/images/iconoContraste.svg'
import IconoVoz from 'assets/images/ico-voz.svg'
import IconoVozAzul from 'assets/images/ico-voz-azul.svg'
import template from './_full-header.pug'

// @vue/component
// @todo the accessibility modal is no longer here. Clean up old methods
export default Vue.extend({
  name: 'full-header-partial',
  template: template(),
  mixins: [ContactMixin],
  components: {
    AppLogo,
    SessionMenu,
    PhoneNumber,
    MunicipalitySelector,
  },

  data() {
    return {
      default: DEFAULT,
      socialMediaItems: SOCIAL_MEDIA,
      modalAccessibility:false,
      modoContraste:false,
      srcicono:"",
      telephone_number: TELEPHONE.number
    }
  },

  computed: {
    ...mapGetters([
      'currentAppConfig', 
      'currentMunicipality', 
      'getAccesibilityActive'
    ]),

    appStoreLinks() {
      const appStoreLinks = []

      if (this.currentAppConfig.google_play_url) {
        appStoreLinks.push({
          icon: 'android',
          link: this.currentAppConfig.google_play_url
        })
      }

      if (this.currentAppConfig.apple_store_url) {
        appStoreLinks.push({
          icon: 'ios',
          link: this.currentAppConfig.apple_store_url
        })
      }

      return appStoreLinks
    },

    // Keep for now for browser testing
    numAtnClient() {
      let num = this.telephone_number.split(' ')
      num = num.map((part) => {
        return part.split('').join(' ')
      })
      num = num.join(', ')
      return num
    }
  },
  
  mounted() {
    $("body").removeClass("ns-sidepanel--overlay-active");
    
    var iconoa = document.getElementById("iconoacce");
    if(iconoa) iconoa.src = IconoAccesibility;
    
    var logoSuperior = document.getElementById("logoSuperior");
    if(logoSuperior) logoSuperior.src = LogoOriginal;
    
    var logoFooter = document.getElementById("logo_footer");
    if(logoFooter) logoFooter.src = LogoOriginal;
    
    $(".ns-layout__container").css('margin-top','2.5rem !important');
  },
  
  methods: {
    ...mapActions([
      'showAccessibilityModal', 
      'openLanguageModal'
    ]),
  
    login() {
      this.$emit('login')
    },

    logout() {
      this.$emit('logout')
    },

    closeMenu() {
      this.$emit('close-menu')
    },

    cambiarFuente(valor){  
      var el = document.querySelector("html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video ")
      var fontSize2 = window.getComputedStyle(el,null).getPropertyValue('font-size')
      var rem = (fontSize2.split("px")[0] / 16)
      
      if(valor =="disminuir"){
        if(rem >= 0.9)
        rem = rem  - 0.1
      }else{
        if(rem <= 1.2 ){
          rem = rem +0.1
        }
      }
      el.style.fontSize = rem+"rem"
    },
   
    applyContrasteCss(){

      // console.log(`applying high contrast for component ${this.$options.name} (${this.contraste})`)

      /** @todo Refactor logo to use refs instead of selectors */
      var logoSuperior = document.getElementById("logoSuperior")
      var logoFooter = document.getElementById("logo_footer")
      var iconoa = document.getElementById("iconoacce")
      var libroReclamo = document.getElementById("complaintsBook");
      
      if(this.contraste){
        /** @todo Refactor into shared method */
        if(logoSuperior) logoSuperior.src = LogoBlanco
        if(logoFooter) logoFooter.src = LogoBlanco
        if(iconoa) iconoa.src = IconoAccesibilityBlanco
        if(libroReclamo) libroReclamo.src = libroReclamoBlanco;
        
        $( ".option-menu" )
          .mouseover(function() {
            $( this ).css("background-color","transparent").css("border","2px solid #1e1e1e");
          })
          .mouseout(function() {
            $( this ).css("background-color","transparent").css("border", "none"); 
            $( ".active" ).css("background-color","transparent").css("border","2px solid #1e1e1e");
          })
        
        $( ".active" ).css("background-color","transparent").css("border","2px solid #1e1e1e");
       
        $( ".footer-link, .boton-acces, .sesion, .idioma, .contacto, .ns-session" )
          .mouseover(function() {
              $( this ).css("cssText","#1e1e1e").css("background-color"," #ffffff")
          })
          .mouseout(function() {
            $( this ).css("cssText","color: #ffffff").css("background-color","")
          });

      } else {
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("color" ,"")
        $(".ns-layout ,.ns-layout__header, .text-black , .text-gray2 , .sidepanel-map__card--item__stop--route , .sidepanel-map__card--item__stop").css("background-color" ,"")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("color" ,"")
        $(".text-primary , .text-gray2-hover , .button-phone , .text-gray3 , .ns-layout__sidebar__map__menu").css("background-color" ,"")
        $("a ,.sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items , .pb-d6 , .sidepanel-map__card--item__point-name").css("color" ,"")
        $(".sidepanel-map__container, .recharge-home__recharges , .recharge-home__cities , .ns-layout__footer__items ,.pb-d6, .sidepanel-map__card--item__point-name,.flex-middle ,.sidepanel-map__header").css( "background-color" ,"")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("color" ,"")
        $(".sidepanel-map--planner__form , .uppercase-vz ,.hidden-xs ,.panel,.ns-field__label,.uppercase ,#fares-side-menu,.flex-column").css("background-color" ,"")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title ").css("color" ,"")
        $("label,.ns-tabs__item,.primay-dark,.text-small, .ns-icon-angle-right ,.h1-main-title,.text-main-title,.ns-session__nav--login ").css("background-color" ,"")
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube,.ns-tabs, .button-tabs,.text-primary-hover,.ns-layout__footer__footer,.hidden-cl").css("color" ,"");
        $(".ns-layout__header--city , .ns-button,.text-info , .ns-icon-youtube ,.mb-d1-2 ,.px-d2 ,.ns-tabs__item ,.panel-schedules ,.ns-tabs, .button-tabs,.ns-layout__footer__footer,.hidden-cl").css("background-color" ,"")
        $(" .pb-d6 ,.panel-schedules ,.px-d2 ").css("color" ,"")  
        $("#iconorestablecer").css("color" ,"#1e1e1e");
        $("#iconorestablecer").css("background-color" ,"#fff")
        $("#textorestablecer").css("color" ,"#1e1e1e");
        $("#textorestablecer").css("background-color" ,"#fff")
        $("#linkhome").css( "color" ,"")
        $(".textomenu").css( "color","")
        $(".tablacss ").css( "color","")
        $(".tablacss,#iconoacce ").css("background-color" ,"")
        $(".ns-icon-angle-left ").css("color" ,"")
        $(".ns-icon-angle-left ,.colorFondo  ").css("background-color" ,"")
        $("#tarifas ").css("background-color" ,"")
        $("body").css("background-color" ,"")
        $("#menuPerfil , #subMenuPerfil, #sublogout , .ns-icon-coins").css("color" ,"")
        $(".preview ").css("color" ,"")
        $(".panel").css("background-color" ,"")
        $(".titulomodal, #TituloAceesibilidad ,#iconoacce ,.text-gray1 ").css("color" ,"")
        $(".button-phone").css("border" ,"none")


        $( ".active" ).css("background-color","").css("border","none");
        
        $( ".active" ).css("background-color","").css("border","none");


        $( ".footer-link , .boton-acces , .sesion , .idioma , .contacto, .ns-session" )
          .mouseover(() => {
            $( this ).css("cssText","").css("background-color"," ");
          })
          .mouseout(() => {
            $( this ).css("cssText","color: ").css("background-color",""); 
          });
        
        $( ".footer-link , .boton-acces , .sesion , .idioma , .contacto, .ns-session" )
          .mouseover(() => {
            $( this ).css("cssText","").css("background-color"," ");
          })
          .mouseout(() => {
            $( this ).css("cssText","color: ").css("background-color",""); 
          });

        if(logoSuperior) logoSuperior.src = LogoOriginal;
        if(logoFooter) logoFooter.src = LogoOriginal;
        if(iconoa) iconoa.src = IconoAccesibility;
      }
      
      
    }
  }
})
