import template from './index.pug';

export default {
  name: 'tooltip',
  template: template(),
  props: {
    position: {
      type: String,
      default: 'bottom' // Puede ser 'top', 'bottom', 'left', o 'right', 'top-rigth', 'bottom-right', 'right-center', 'left-center', 'bottom-left'
    },
    hover: {
      type: Boolean,
      default: false // Controla si se activa con hover
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    toggleTooltip() {
      if (!this.hover) { // Solo si no está activado por hover
        this.isVisible = !this.isVisible;
      }
    },
    showTooltip() {
      if (this.hover) {
        this.isVisible = true;
      }
    },
    hideTooltip() {
      if (this.hover) {
        this.isVisible = false;
      }
    }
  }
}