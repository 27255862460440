import Vue from 'vue'
import {TELEPHONE} from 'js/lib/info.js'

import AppLogo from 'js/components/layout-menu/_app-logo.js'

import template from './_portable-header.pug'

// @vue/component
export default Vue.extend({
  template: template(),

  components: {
    AppLogo
  },

  props: {
    controlMenu: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      telephone_href: TELEPHONE.href,
      telephone_number: TELEPHONE.number
    }
  },

  methods: {
    openSidebarMenu() {
      this.controlMenu.toggle()
    },

    closeMenu() {
      this.$emit('close-menu')
    }
  }
})
