import Vue from 'vue'

import template from './_login-form.pug'
import {mapGetters} from 'vuex'
import tooltip from '../tooltip'

export default Vue.extend({
  name: 'login-form-partial',
  template: template(),
  components: { tooltip },
  props: {
    step: {
      type: String,
      default: 'login'
    }
  },
  data() {
    return {
      web_title: Vue.store.state.webTitle,
      steps: ['login', 'resetPassword', 'resetPasswordFinish', 'register', 'registerFinish'],
      localStep: 'login',
      loginEmail: '',
      loginPassword: '',
      loginError: undefined,
      resetPasswordEmail: '',
      resetPasswordError: undefined,
      registerEmail: '',
      registerPassword: '',
      registerPasswordConfirmation: '',
      registerAcceptConditions: false,
      registerAcceptConditionsError: undefined,
      keyCloakError: '',
      registerErrors: {
        email: undefined,
        password: undefined,
        password_confirmation: undefined
      },
      fonts : ["cursive"],
      captchaValue: '',
      inputCaptcha:'',
      inputCaptchaError :undefined,
      shouldShowConditions: false,
      useCaptcha: false
    }
  },
  computed: {
    ...mapGetters(['usingKeyCloak']),
    loginUserErrorClass() {
      const vm = this

      if (vm.loginError) {
        return 'error'
      }
      return undefined
    },
    getLoginPanelTitle() {
      return this.$t(`loginForm.${this.localStep}.title`, {web_title: this.web_title})
    },
    acceptTermsVoice() {
      return !this.registerAcceptConditions ? this.$t('loginForm.register.helptextConditionsCheckboxSelect') : this.$t('loginForm.register.helptextConditionsCheckboxDeselect')
    },
    successResetPassword(){
      return this.$t('loginForm.resetPassword.textHelp1')
    }
  },
  watch: {
    step(newStep) {
      if (this.localStep !== newStep) {
        this.localStep = newStep
      }
    },
    localStep(newStep) {
      this.$emit('change-step', newStep)
      this.loginError = undefined
      this.resetPasswordEmail = ''
      this.resetPasswordError = undefined
      // console.log("Updating visible tabs")
      this.$nextTick(() => {
        this.customaccessibilityTabs.setup(this.$options.name, "#access-form")
      })
    }
  },
  created() {
    this.localStep = this.step
  },
  mounted(){
    this.gencaptcha()
    this.setcaptcha()
  },
  methods: {

    login() {
      const vm = this
      this.inputCaptchaError =undefined
      if (vm.loginEmail) {
        vm.loginEmail = $.trim(vm.loginEmail)
      }

      vm.loginError = undefined


      if(this.inputCaptcha=== this.captchaValue){
          vm.$auth.login({email: vm.loginEmail, password: vm.loginPassword}).then(
          vm.handleLoginSuccess,
          vm.handleLoginError,
          
        )
      }else{
        
        this.inputCaptchaError = this.$t('contact.captcha')
    }

     
    },

    register() {
      const vm = this

      if (vm.registerEmail) {
        vm.registerEmail = $.trim(vm.registerEmail)
        vm.registerPassword = $.trim(vm.registerPassword)
        vm.registerPasswordConfirmation = $.trim(vm.registerPasswordConfirmation)
      }

      vm.registerErrors = {
        email: undefined,
        password: undefined,
        password_confirmation: undefined
      }
      vm.registerAcceptConditionsError = undefined

      if (!vm.registerAcceptConditions) {
        vm.registerAcceptConditionsError =  vm.$t('loginForm.register.conditionsError')
      } else {
        if(this.inputCaptcha=== this.captchaValue){
          vm.$auth.registration({
            email: vm.registerEmail,
            password: vm.registerPassword,
            password_confirmation: vm.registerPasswordConfirmation
          }).then(
            vm.handleRegisterSuccess,
            vm.handleRegisterError
          )
        }else{
            this.inputCaptchaError = this.$t('contact.captcha')
          }
      }
    },

    resetPassword() {
      const vm = this

      if (vm.resetPasswordEmail) {
        vm.resetPasswordEmail = $.trim(vm.resetPasswordEmail)
      }

      vm.resetPasswordError = undefined
      if(this.inputCaptcha=== this.captchaValue){
        vm.$auth.resetPassword({email: vm.resetPasswordEmail}).then(
          vm.handleResetPasswordSuccess,
          vm.handleResetPasswordError
        )
      }else{
        this.inputCaptchaError = this.$t('contact.captcha')
      }
    },

    handleLoginSuccess(response) {
      const vm = this
      vm.$auth.setToken(response.data.data.jwt)
      Vue.store.dispatch('loadCurrentUser').then(valid => {
        if (valid) {
          vm.$emit('close-panel')
        } else {
          vm.loginError = vm.$t('errors.invalidUser')
        }
      })
    },

    handleLoginError(response) {
      if (response.status === 422) {
        this.loginError = response.data.errors.session
      }
    },

    handleResetPasswordSuccess() {
      this.localStep = 'resetPasswordFinish'
    },

    handleResetPasswordError(response) {
      if (response.status === 422) {
        this.resetPasswordError = response.data.errors.email
      }
    },

    handleRegisterSuccess(response) {
    console.log('handleRegisterSuccess', response)
      const vm = this
      vm.$auth.setToken(response.jwt)
      Vue.store.dispatch('loadCurrentUser').then(() => {
        vm.localStep = 'registerFinish'
      })
    },

    handleRegisterError(response) {
      try {
        // Check if the error comes from keycloak
        if(response.bodyText && response.bodyText.toLowerCase().includes('keycloak')) {
          // We have to extract the error from the body
          let pattern = new RegExp(/\{.*\}$/)
          let patternNums = new RegExp(/[0-9]+/g)
          let keyCloakError = JSON.parse(response.bodyText)
          keyCloakError = JSON.parse(keyCloakError.errors.match(pattern)[0])
          console.log("keyCloakErrorObject", keyCloakError)
          // Get any numbers which may exist and pass them to the translation
          let nums = [...keyCloakError.error_description.matchAll(patternNums)]
          this.keyCloakError = this.$t(`keycloak.${keyCloakError.error}`, nums || [])
        } 
        if (response.status === 422) {
            _.each(response.data.errors, (error, field) => {
            this.registerErrors[field] = error
          })
        }
      } catch(err) {
        console.log(err)
        this.keyCloakError = this.$t(`keycloak.registrationGeneric`)
      }
    },

    resetPasswordLink() {
      this.localStep = 'resetPassword'
    },

    createAccountLink() {
      this.localStep = 'register'
    },

    closePanel() {
      this.$emit('close-panel')
      const vm = this
      this.$nextTick(function(){
        vm.customaccessibilityTabs.setup(vm.$options.name)
      })
    },

    backToLogin() {
      this.localStep = 'login'
    },

    closePanelHome() {
      if (this.$route.name !== 'home' && !_.includes(this.$route.name, 'municipality')) {
        this.$router.push({name: 'home'})
      }
      this.$emit('close-panel')
    },

    showConditions() {
      if (this.shouldShowConditions) {
        const routeData = 'https://autgc.org/aviso-legal/'
        window.open(routeData)
      } else {
        const routeData = this.$router.resolve({name: 'privacy-policy'})
        window.open(routeData.href)
      }
    },

    gencaptcha()
    {
        let value = btoa(Math.random()*1000000000);
        value = value.substr(0,5 + Math.random()*5);
        this.captchaValue = value;
    },

    setcaptcha()
    {
        let html = this.captchaValue.split("").map((char)=>{
          const rotate = -20 + Math.trunc(Math.random()*30);
          const font = Math.trunc(Math.random()*this.fonts.length);
          return`<span
          style="
          transform:rotate(${rotate}deg);
          font-family:${font[font]};
          "
          >${char} </span>`;
      }).join("");
      document.querySelector(".preview").innerHTML = html;
    },

     /**
     * Override the mixin handler
     * @param {Event} event 
     * @returns ?Event 
     */
    handleKeystroke(event) {
      // console.log('Keystroke', event.target)
      switch(event.code) {
        case 'Escape':
          this.closePanel()
          break
        case 'Enter':
        case 'Space':
          event.target.click()
          break
        default:
          return
      }
    },

    applyContrasteCss(){
      // console.log(`applying high contrast for component ${this.$options.name} (${this.contraste})`)

      if(this.contraste){
        $(".preview ").css( "cssText" ,"color: #1e1e1e !important")
      }else{
        $(".preview ").css( "cssText" ,"color:  ")
      }
    }
  }
})
