const TELEPHONE = {
  href: 'tel:+351255190900',
  number: '255 190 900'
}

const DEFAULT = 'default'

const COMPLAINTS_URL = 'https://www.consumidor.gov.pt/livro-de-reclamacoes.aspx'

const SOCIAL_MEDIA = [
  {
    name: 'Facebook',
    icon: 'facebook',
    link: 'https://www.facebook.com/RodoAmarante-Lda-102646262028909'
  },
  {
    name: 'Linkedin',
    icon: 'linkedin-in-square',
    link: 'https://www.linkedin.com/company/rodoamarante-lda/'
  },
  {
    name: 'Instagram',
    icon: 'instagram',
    link: 'https://www.instagram.com/rodoamarante/'
  }
]

const ADDRESS = 'Rua António Carneriro Ed. Navarras Fração H Loja 34 - 4600-012 - AMARANTE'

const EMAIL_ADDRESS = {
  username: 'geral',
  domain: 'rodoamarante.pt'
}

export {
  TELEPHONE,
  COMPLAINTS_URL,
  SOCIAL_MEDIA,
  ADDRESS,
  EMAIL_ADDRESS,
  DEFAULT
}
