import Vue from 'vue'
import {mapGetters} from 'vuex'

import template from './_app-logo.pug'

const Logo = require('assets/images/logo.svg')

// @vue/component
export default Vue.extend({
  template: template(),

  data() {
    return {
      logo: Logo
    }
  },

  computed: {
    ...mapGetters(['homeRoute'])
  },

  methods: {
    goToHome() {
      this.$emit('click')
      this.$router.push(this.homeRoute)
    }
  }
})
