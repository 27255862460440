import Vue from 'vue'
import {mapGetters} from 'vuex'

import HeaderMenu from 'js/components/layout-menu/header/index.js'
import SidebarMenu from 'js/components/layout-menu/sidebar/index.js'

import LoginForm from './_login-form.js'

import template from './index.pug'

// @vue/component
Vue.component('layout-menu', {
  name: 'layout-menu',
  template: template(),

  components: {
    LoginForm, SidebarMenu, HeaderMenu
  },

  data() {
    return {
      showPanel: false,
      showLanguageSelector: false,
      step: 'login',
      openedMenu: false
    }
  },

  computed: {
    isLayoutInfo() {
      return this.$route.meta.layoutInfo
    },

    isFixedLayout() {
      return this.$route.meta.fixedLayout
    },

    isMixinLayout() {
      return this.$route.meta.mixinLayout
    },

    isHeaderHidden() {
      return this.$route.meta.headerHidden
    },

    layoutInfoClass() {
      return this.isLayoutInfo ? 'info' : ''
    },

    layoutMixinClass() {
      return this.isMixinLayout ? 'mixin-layout' : ''
    },

    layoutNoHeader() {
      return this.isHeaderHidden ? 'ns-layout--no-header' : ''
    },

    ...mapGetters(['homeRoute'])
  },

  watch: {
    '$route'() {
      this.checkDevice()
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.checkDevice()
    })
  },

  created() {
    window.addEventListener('resize', this.checkDevice)
  },

  destroyed() {
    window.removeEventListener('resize', this.checkDevice)
  },

  methods: {
    opened() {
      this.openedMenu = true
      this.setFixedLayout()
    },

    closed() {
      this.openedMenu = false
      this.removeFixedLayout()
    },

    checkDevice() {
      if (this.isFixedLayout) {
        setTimeout(() => {
          this.setFixedLayout()
        }, 25)
      } else {
        this.removeFixedLayout()
      }

      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },

    setFixedLayout() {
      document.documentElement.classList.add('device-fixed')
    },

    removeFixedLayout() {
      if (!this.isFixedLayout) {
        this.removeFixedClass()
      }
    },

    removeFixedClass() {
      document.documentElement.classList.remove('device-fixed')
    },

    closePanel() {
      this.showPanel = false
      const vm = this
      this.$nextTick(function(){
        vm.customAccesibilityTabs.setup(vm.$options.name)
      })
    },

    deviceChange(device) {
      this.$store.commit('setDevice', device)
    },

    closeMenu(props) {
      if (props.toggle && this.$isPortable && this.openedMenu) props.toggle()
      this.removeFixedClass()
    },

    logout() {
      this.$auth.logout()
      this.$notifications.send({
        id: 'logout-success', text: this.$t('logout.successMessage'), type: 'success', close: true
      })
      if (this.$currentAppConfig.code === 'gc') {
        this.$router.push({
          name: 'home'
        })
      } else {
        this.$router.push(this.homeRoute)
      }
    },

    login() {
      this.changeStep('login')
      this.showPanel = true
    },

    register() {
      this.changeStep('register')
      this.showPanel = true
    },

    changeStep(step) {
      this.step = step
      let vm = this
      this.$nextTick(function(){
        vm.customAccesibilityTabs.setup(vm.$options.name, "#access-form")
      })
    }
  }
})
