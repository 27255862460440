import Vue from 'vue'
import template from './index.pug'

export default Vue.extend({
  name: 'router-link-wrapper',
  template: template(),
  props:  {
    to: {
      type: Object,
      required: true
    },
    linkText: {
      type: String,
      required: true
    },
    tabindex: {
      type: Number,
      default: 50,
      required: false
    },
    say: {
      type: String,
      default: '',
      required: false
    }
  }
})