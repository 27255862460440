const TransportRequest = () => import('js/pages/transport-request/index.js')

export default [
  {
    path: '/transport-request',
    name: 'transport-request',
    component: TransportRequest,
    meta: {
      layoutInfo: true,
      layout: 'default'
    }
  }
]
