import Vue from 'vue'
import {mapGetters} from 'vuex'

import CityImage from 'assets/images/cities/icons/city.svg'

import template from './_session-menu.pug'

// @vue/component
export default Vue.extend({
  name: 'session-menu-partial-custom-rd',
  template: template(),

  props: {
    side: {
      type: String,
      default: undefined
    },
    bubble: {
      type: Object,
      default: undefined
    },
    closableMobileMenu: {
      type: Object,
      default: undefined
    }
  },

  data() {
    return {
      municipalityMenuItems: [
        {
          id: 'balance',
          routeName: 'data-balance',
          icon: 'coins'
        },
        {
          id: 'payment-medias',
          routeName: 'data-payment-medias',
          icon: 'wallet'
        },
        {
          id: 'travels',
          routeName: 'data-travels',
          icon: 'ticket'
        }
      ]
    }
  },

  computed: {
    nameNormalized() {
      const vm = this

      if (vm.$municipality) {
        return vm.$municipality.name_normalized
      }
      return undefined
    },

    country() {
      const vm = this

      if (vm.$municipality) {
        return vm.$municipality.country_alpha2.toLowerCase()
      }
      return undefined
    },

    municipalityIcon() {
      const vm = this

      if (vm.$municipality) {
        return vm.$municipality.image_url || CityImage
      }
      return undefined
    },

    ...mapGetters(['canSelectMunicipality'])
  },

  methods: {
    itemMenuClass(route) {
      return _.includes(this.$route.name, route) ? 'text-primary' : ''
    },

    itemMenuInverseClass(route) {
      return _.includes(this.$route.name, route) ? '' : 'text-gray2'
    },

    goTo(route) {
      this.closeMenu()
      this.$router.push({path: route})
    },

    goToMunicipalityRoute(route) {
      const vm = this

      vm.closeMenu()
      if (vm.$municipality) {
        vm.$router.push({
          name: `municipality-${route}`,
          params: {
            country: vm.country,
            municipality: vm.nameNormalized
          }
        })
      } else {
        vm.$router.push({name: route})
      }
    },

    goToOnlyMunicipalityRoute(route) {
      const vm = this

      if (vm.$municipality) {
        vm.closeMenu()
        vm.$router.push({
          name: `municipality-${route}`,
          params: {
            country: vm.country,
            municipality: vm.nameNormalized
          }
        })
      }
    },

    logout() {
      this.closeMenu()
      this.$emit('logout')
    },

    closeMenu() {
      const vm = this
      if (vm.closableMobileMenu && vm.$isPortable) {
        vm.closableMobileMenu.toggle()
      }
      if (vm.bubble && !vm.$isPortable) {
        vm.bubble.close()
      }
    }
  }
})
