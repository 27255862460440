import Vue from 'vue'
import {mapGetters} from 'vuex'

import CitySelect from './city-select'

import template from './index.pug'

export default Vue.extend({
  template: template(),

  components: {
    CitySelect
  },

  computed: {
    ...mapGetters(['availableMunicipalities'])
  },

  methods: {
    municipalityClass(index) {
      if (index === 0) {
        return 'end-sm'
      }
      return ''
    }
  }
})
