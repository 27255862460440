import MapPublicRoutes from 'js/vue-plugins/vue-router/routes/municipalities/map-public-routes'
import PublicRoutes from 'js/vue-plugins/vue-router/routes/municipalities/public-routes'
import PrivateRoutes from 'js/vue-plugins/vue-router/routes/municipalities/private-routes'

const Municipality = () => import('js/pages/municipality/index.js')

export default {
  path: '/:country/:municipality',
  name: 'municipality',
  component: Municipality,
  redirect: {name: HOME_ROUTE},
  children: [].concat(
    MapPublicRoutes,
    PublicRoutes,
    PrivateRoutes
  )
}
